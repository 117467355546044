import logo from './logo.svg';
import _ from "lodash"
import './App.css';
import { useEffect, useState } from 'react';

let prevBoard;
let lastX=-1;
let lastY=-1;

function App() {
  const [renderBoard, setRenderBoard] = useState();
  

  const [colorSelected, setColorSelected] = useState("color1");
  const [pieceSelected, setPieceSelected] = useState("king");
  const [counter, setCounter] = useState(0);

  const handleClickTile=(indX, indY)=>{

    // if (renderBoard[indX][indY].clicked) return;


    
    const renderBoardClone = _.cloneDeep(renderBoard);

    renderBoardClone[indX][indY].clicked=true;
    setCounter(counter+1)

    if (pieceSelected=="king"){
      renderBoardClone[indX][indY].color = "b-" + colorSelected;
      renderBoardClone[Math.min(indX+1, 7)][indY].color = "b-" + colorSelected;
      renderBoardClone[Math.max(indX-1, 0)][indY].color = "b-" + colorSelected;
      renderBoardClone[indX][Math.min(indY+1, 7)].color = "b-" + colorSelected;
      renderBoardClone[indX][Math.max(indY-1, 0)].color = "b-" + colorSelected;
    }else if (pieceSelected=="tower"){
      for (let i=0; i<8; i++){
        renderBoardClone[i][indY].color="b-" + colorSelected;
        renderBoardClone[indX][i].color="b-" + colorSelected;

      }
    }else if (pieceSelected=="alfil"){
      for (let i=0; i<8; i++ ){
        if(indX+i<8 && indY+i<8) renderBoardClone[indX+i][indY+i].color = "b-" + colorSelected;
        if(indX+i<8 && indY-i>=0) renderBoardClone[indX+i][indY-i].color = "b-" + colorSelected;
        if(indX-i>=0 && indY+i<8) renderBoardClone[indX-i][indY+i].color = "b-" + colorSelected;
        if(indX-i>=0 && indY-i>=0) renderBoardClone[indX-i][indY-i].color = "b-" + colorSelected;
      }
    
    }else if (pieceSelected=="queen"){
      for (let i=0; i<8; i++ ){
        if(indX+i<8 && indY+i<8) renderBoardClone[indX+i][indY+i].color = "b-" + colorSelected;
        if(indX+i<8 && indY-i>=0) renderBoardClone[indX+i][indY-i].color = "b-" + colorSelected;
        if(indX-i>=0 && indY+i<8) renderBoardClone[indX-i][indY+i].color = "b-" + colorSelected;
        if(indX-i>=0 && indY-i>=0) renderBoardClone[indX-i][indY-i].color = "b-" + colorSelected;
      }
      for (let i=0; i<8; i++){
        renderBoardClone[i][indY].color="b-" + colorSelected;
        renderBoardClone[indX][i].color="b-" + colorSelected;

      }
    }
    else if (pieceSelected=="horse"){
      
      renderBoardClone[indX][indY].color = "b-" + colorSelected;
      renderBoardClone[Math.min(indX+1, 7)][indY].color = "b-" + colorSelected;
      renderBoardClone[Math.min(indX+2, 7)][indY].color = "b-" + colorSelected;

      if (indX+2<8) {
        renderBoardClone[Math.min(indX+2, 7)][Math.min(indY+1, 7)].color = "b-" + colorSelected;
        renderBoardClone[Math.min(indX+2, 7)][Math.max(indY-1, 0)].color = "b-" + colorSelected;
      }

      renderBoardClone[Math.max(indX-1, 0)][indY].color = "b-" + colorSelected;
      renderBoardClone[Math.max(indX-2, 0)][indY].color = "b-" + colorSelected;

      if (indX-2>=0) {
        renderBoardClone[Math.min(indX-2, 7)][Math.min(indY+1, 7)].color = "b-" + colorSelected;
        renderBoardClone[Math.min(indX-2, 7)][Math.max(indY-1, 0)].color = "b-" + colorSelected;
      }

      renderBoardClone[indX][Math.min(indY+1, 7)].color = "b-" + colorSelected;
      renderBoardClone[indX][Math.min(indY+2, 7)].color = "b-" + colorSelected;

      if (indY+2>=0) {
        renderBoardClone[Math.min(indX-1, 7)][Math.min(indY+2, 7)].color = "b-" + colorSelected;
        renderBoardClone[Math.min(indX+1, 7)][Math.max(indY+2, 0)].color = "b-" + colorSelected;
      }

      renderBoardClone[indX][Math.max(indY-1, 0)].color = "b-" + colorSelected;
      renderBoardClone[indX][Math.max(indY-2, 0)].color = "b-" + colorSelected;

      if (indY-2>=0) {
        renderBoardClone[Math.min(indX-1, 7)][Math.min(indY-2, 7)].color = "b-" + colorSelected;
        renderBoardClone[Math.min(indX+1, 7)][Math.max(indY-2, 0)].color = "b-" + colorSelected;
      }

    }

    setRenderBoard(renderBoardClone);
    prevBoard=renderBoardClone;

  }

  const handleMouseOver=(indX, indY)=>{
    if (lastX == indX && lastY==indY) return;

    // lastX=indX;
    // lastY=indY;
    // if (renderBoard[indX][indY].clicked) return;
    
    const renderBoardClone = _.cloneDeep(prevBoard);

    renderBoardClone[indX][indY].clicked=true;

    if (pieceSelected=="king"){
      renderBoardClone[indX][indY].color = "b-" + colorSelected;
      renderBoardClone[Math.min(indX+1, 7)][indY].color = "b-" + colorSelected;
      renderBoardClone[Math.max(indX-1, 0)][indY].color = "b-" + colorSelected;
      renderBoardClone[indX][Math.min(indY+1, 7)].color = "b-" + colorSelected;
      renderBoardClone[indX][Math.max(indY-1, 0)].color = "b-" + colorSelected;
    }else if (pieceSelected=="tower"){
      for (let i=0; i<8; i++){
        renderBoardClone[i][indY].color="b-" + colorSelected;
        renderBoardClone[indX][i].color="b-" + colorSelected;

      }
    }else if (pieceSelected=="alfil"){
      for (let i=0; i<8; i++ ){
        if(indX+i<8 && indY+i<8) renderBoardClone[indX+i][indY+i].color = "b-" + colorSelected;
        if(indX+i<8 && indY-i>=0) renderBoardClone[indX+i][indY-i].color = "b-" + colorSelected;
        if(indX-i>=0 && indY+i<8) renderBoardClone[indX-i][indY+i].color = "b-" + colorSelected;
        if(indX-i>=0 && indY-i>=0) renderBoardClone[indX-i][indY-i].color = "b-" + colorSelected;
      }
    
    }else if (pieceSelected=="queen"){
      for (let i=0; i<8; i++ ){
        if(indX+i<8 && indY+i<8) renderBoardClone[indX+i][indY+i].color = "b-" + colorSelected;
        if(indX+i<8 && indY-i>=0) renderBoardClone[indX+i][indY-i].color = "b-" + colorSelected;
        if(indX-i>=0 && indY+i<8) renderBoardClone[indX-i][indY+i].color = "b-" + colorSelected;
        if(indX-i>=0 && indY-i>=0) renderBoardClone[indX-i][indY-i].color = "b-" + colorSelected;
      }
      for (let i=0; i<8; i++){
        renderBoardClone[i][indY].color="b-" + colorSelected;
        renderBoardClone[indX][i].color="b-" + colorSelected;

      }
    }
    else if (pieceSelected=="horse"){
      
      renderBoardClone[indX][indY].color = "b-" + colorSelected;
      renderBoardClone[Math.min(indX+1, 7)][indY].color = "b-" + colorSelected;
      renderBoardClone[Math.min(indX+2, 7)][indY].color = "b-" + colorSelected;

      if (indX+2<8) {
        renderBoardClone[Math.min(indX+2, 7)][Math.min(indY+1, 7)].color = "b-" + colorSelected;
        renderBoardClone[Math.min(indX+2, 7)][Math.max(indY-1, 0)].color = "b-" + colorSelected;
      }

      renderBoardClone[Math.max(indX-1, 0)][indY].color = "b-" + colorSelected;
      renderBoardClone[Math.max(indX-2, 0)][indY].color = "b-" + colorSelected;

      if (indX-2>=0) {
        renderBoardClone[Math.min(indX-2, 7)][Math.min(indY+1, 7)].color = "b-" + colorSelected;
        renderBoardClone[Math.min(indX-2, 7)][Math.max(indY-1, 0)].color = "b-" + colorSelected;
      }

      renderBoardClone[indX][Math.min(indY+1, 7)].color = "b-" + colorSelected;
      renderBoardClone[indX][Math.min(indY+2, 7)].color = "b-" + colorSelected;

      if (indY+2>=0) {
        renderBoardClone[Math.min(indX-1, 7)][Math.min(indY+2, 7)].color = "b-" + colorSelected;
        renderBoardClone[Math.min(indX+1, 7)][Math.max(indY+2, 0)].color = "b-" + colorSelected;
      }

      renderBoardClone[indX][Math.max(indY-1, 0)].color = "b-" + colorSelected;
      renderBoardClone[indX][Math.max(indY-2, 0)].color = "b-" + colorSelected;

      if (indY-2>=0) {
        renderBoardClone[Math.min(indX-1, 7)][Math.min(indY-2, 7)].color = "b-" + colorSelected;
        renderBoardClone[Math.min(indX+1, 7)][Math.max(indY-2, 0)].color = "b-" + colorSelected;
      }

    }

    setRenderBoard(renderBoardClone);
    // prevBoard=renderBoardClone;

  }


  useEffect(()=>{
    let board = [];
    for (let i = 0; i < 8; i++) {
      let row = [];
      for (let j = 0; j < 8; j++) {
        row.push({clicked:false, color:""});
      }
      board.push(row);
    }
    
    setRenderBoard(board)

    prevBoard=board;
  }, [])


  return (
    <div className="App">
     
      <div  className="Board-container">
      {renderBoard?.map((row, indX)=>{
      return row.map((column, indY)=>{
        return <div className={`BoardTile ${indY%2==0?indX%2==0?"tile-black":"":indX%2==1?"tile-black":""} ${column.color}`} style={{cursor:column.clicked?"not-allowed":"pointer"}} onClick={()=>{
          console.log("Ejecuto", indX, indY)
          handleClickTile(indX, indY)}} onMouseOver={()=>handleMouseOver(indX, indY)} onMouseLeave={()=>setRenderBoard(prevBoard)}>
          {/* ({indX}, {indY}) */}
          </div>
      })
    })}
      </div>
      <select name="colors" id="colors" className={"b-"+ colorSelected} value={colorSelected} style={{width:"100px"}} onChange={(e)=>{setColorSelected(e.target.value)}}>
        <option value="color1" className='b-color1' style={{width:"100px"}}></option>
        <option value="color2" className='b-color2' style={{width:"100px"}}></option>
        <option value="color3" className='b-color3' style={{width:"100px"}}></option>
        {/* <option value="color4" className='b-color4' style={{width:"100px"}}></option>
        <option value="color5" className='b-color5' style={{width:"100px"}}></option>
        <option value="color6" className='b-color6' style={{width:"100px"}}></option>
        <option value="color7" className='b-color7' style={{width:"100px"}}></option> */}
 
      </select>

      <select name="pieces" id="pieces" value={pieceSelected} onChange={(e)=>{setPieceSelected(e.target.value)}}>
        <option value="king">king</option>
        <option value="tower">tower</option>
        <option value="alfil">alfil en ingles</option>
        <option value="horse">horse</option>
        <option value="queen">queen</option>

      </select>
      <p>Clicks: {counter}</p>
      <img src={"https://img.youtube.com/vi/xfmsxwR4btA/hqdefault.jpg"} width={300}/>
    </div>
  );
}

export default App;
